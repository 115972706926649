.device-slider {
  position: relative;
}

/* Pfeile */
.device-slider .slick-arrow {
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 1;
  transition: all 0.3s ease;
}

.device-slider .slick-arrow:hover {
  background-color: white;
}

.device-slider .slick-prev {
  left: 16px;
}

.device-slider .slick-next {
  right: 16px;
}

.device-slider .slick-prev:before,
.device-slider .slick-next:before {
  color: #2563eb;
  opacity: 1;
}

/* Dots */
.device-slider .slick-dots {
  bottom: 16px;
}

.device-slider .slick-dots li {
  margin: 0 2px;
}

.device-slider .slick-dots li button:before {
  display: none;
}

.device-slider .slick-dots li.slick-active button {
  width: 16px;
  background-color: #2563eb;
}

/* Slides */
.device-slider .slick-slide {
  padding: 0;
  position: relative;
}

/* Fade-Animation */
.device-slider .slick-slide {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.device-slider .slick-slide.slick-active {
  opacity: 1;
}
